* {
  box-sizing: border-box;
}
body,
html {
  padding: 0;
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -moz-font-feature-settings: "kern" 1;
  -ms-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  -webkit-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;

  font-family: "Helvetica Neue";
  font-size: 16px;
  letter-spacing: 0.01em;
}

.notes {
  padding: 10px;
  padding-top: 40px;
  padding-bottom: 100px;
}

.slate-todo-list-checked span[contenteditable="true"] {
  text-decoration: none !important;
}
.slate-todo-list-checked [type="checkbox"] {
  background-color: black;
}
[type="checkbox"] {
  cursor: pointer;
  outline: none;
  appearance: none;
  border-radius: 0;
  border: 1px solid black;
}
[type="checkbox"]:focus,
[type="checkbox"]:active {
  outline: none;
}

.document-link {
  position: relative;
}
.date {
  display: none;
  font-size: 16px;
  letter-spacing: 0em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
}
.document-link:hover .date {
  display: block;
}

[data-slate-editor] {
  min-height: 90vh;
  padding: 30px;
  padding-top: 50px;
}

.sign-in {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: white;
  background: black;
  line-height: 1;
  padding: 12px 20px;
  letter-spacing: -0.01em;
  border-radius: 2rem;
  transition: 0.3s ease-out border-radius, 0.3s ease-out transform;
}
.sign-in:hover {
  border-radius: 0;
}

::selection {
  background-color: #111;
}

.timer {
  display: block;
  width: 100%;
  text-align: center;
  position: fixed;
  top: 10px;
  left: 0;
  color: black;
  text-decoration: none;
}

.document-link {
  display: block;
  margin-bottom: 5px;
  padding-right: 90px;
  color: black;
  text-decoration: unset;
  font-size: 28px;
  letter-spacing: -0.03em;
}
.document-link:hover::before {
  display: inline-block;
}
.document-link::before {
  background-color: black;
  height: 18px;
  width: 18px;
  display: inline-block;
  display: none;
  content: " ";
  border-radius: 50%;
  margin-right: 10px;
}

#create-new-note {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px;
}
#create-new-note div {
  border: 1px solid black;
  padding: 15px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  opacity: 0.3;
  transition: 0.2s ease-out opacity, 0.2s ease-out transform;
}
#create-new-note div:hover,
#create-new-note div:focus {
  opacity: 1;
}
#create-new-note div:active {
  transform: scale(0.98);
}
